<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
            <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
          <el-form-item label='商品名称' prop='goodsid' :label-width="formLabelWidth">
              <el-select v-model="form.goodsid" placeholder="请选择" @change="getGoodsSkuListInfo(form.goodsid)">
                  <el-option
                          v-for="item in goodsList"
                          :key="item.id"
                          :label="item.goodsname"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='商品套餐' prop='skuid' :label-width="formLabelWidth" v-if="form.goodsid!=''">
              <el-select v-model="form.skuid" placeholder="请选择">
                  <el-option
                          v-for="item in skuList"
                          :key="item.id"
                          :label="item.skuname"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='来源' prop='source' :label-width="formLabelWidth">
              <el-select v-model="form.source" placeholder="请选择">
                  <el-option
                          v-for="item in sourceOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
            <el-form-item label='兑换码名称' prop='name' :label-width="formLabelWidth">
              <el-input placeholder='请输入兑换码名称' clearable autocomplete="off" v-model='form.name' size="small"  />
            </el-form-item>
            <el-form-item label='兑换码' prop='excode' :label-width="formLabelWidth">
                <el-input placeholder="请输入兑换码" v-model="form.excode" class="input-with-select" style="width: 350px;">
                    <el-button slot="append" style="color:#409EFF" @click="getRandomCoding">生成兑换码</el-button>
                </el-input>
            </el-form-item>
<!--          <el-form-item label='类型' prop='type' :label-width="formLabelWidth">-->
<!--              <el-select v-model="form.type" placeholder="请选择">-->
<!--                  <el-option-->
<!--                          v-for="item in typeOptions"-->
<!--                          :key="item.value"-->
<!--                          :label="item.label"-->
<!--                          :value="item.value">-->
<!--                  </el-option>-->
<!--              </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label='使用类型' prop='usetype' :label-width="formLabelWidth">
              <el-select v-model="form.usetype" placeholder="请选择">
                  <el-option
                          v-for="item in usetypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='是否启用' prop='isuse' :label-width="formLabelWidth">
              <el-select v-model="form.isuse" placeholder="请选择">
                  <el-option
                          v-for="item in isuseOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
<!--            <el-form-item label='生成人id' prop='operid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入生成人id' clearable autocomplete="off" v-model='form.operid' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='生成人' prop='opername' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入生成人' clearable autocomplete="off" v-model='form.opername' size="small"  />-->
<!--            </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertExcode,updateExcode,deleteExcode,bacthDelExcode,getGoodsSpuList,getGoodsSkuList } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo,randomCoding } from "@/util/util"
  export default {
    name: "user",
    data(){
      const isuseOptions=[
            {
                value:"1",
                label:"启用",
            },
            {
                value:"0",
                label:"禁用",
            },
      ]
      const typeOptions=[
          {
              value:"1",
              label:"共享",
          },
          {
              value:"2",
              label:"独享",
          },
      ]
      const sourceOptions=[
          {
              value:"1",
              label:"活动发放",
          },
          {
              value:"2",
              label:"用户购买",
          },
          {
              value:"3",
              label:"推广赠送",
          },
          {
              value:"4",
              label:"抽奖",
          },
          {
              value:"5",
              label:"其他",
          },
          {
              value:"6",
              label:"市场营销",
          },
      ]
      const usetypeOptions=[
          {
              value:"1",
              label:"单次",
          },
          {
              value:"2",
              label:"多次",
          },
      ]
      const columns = [
          // {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'name',label: '兑换码名称'},
          {prop: 'excode',label: '兑换码'},
          {prop: 'goodsid',label: '商品id'},
          {prop: 'skuid',label: '商品套餐id'},
          {prop: 'type',label: '类型',formatter: row => this.typeState(row)},
          {prop: 'source',label: '来源',formatter: row => this.sourceState(row)},
          {prop: 'usetype',label: '使用类型',formatter: row => this.usetypeState(row)},
          {prop: 'isuse',label: '是否启用',formatter: row => this.isuseState(row)},
          {prop: 'orderid',label: '订单号'},
          {prop: 'operid',label: '生成人id'},
          {prop: 'opername',label: '生成人'},
          {prop: 'createTime',label: '创建时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'name',
            label: '兑换码名称',
            el: {placeholder: '请输入兑换码名称', clearable: true},
          },
          {
            type: 'input',
            id: 'excode',
            label: '兑换码',
            el: {placeholder: '请输入兑换码', clearable: true},
          },
          {
            type: 'input',
            id: 'operid',
            label: '生成人id',
            el: {placeholder: '请输入生成人id', clearable: true},
          },
          {
            type: 'input',
            id: 'opername',
            label: '生成人',
            el: {placeholder: '请输入生成人', clearable: true},
          },
          {
            type: 'input',
            id: 'goodsid',
            label: '商品id',
            el: {placeholder: '请输入商品id', clearable: true},
          },
          {
            type: 'input',
            id: 'skuid',
            label: '商品套餐id',
            el: {placeholder: '请输入商品套餐id', clearable: true},
          },
          {
              type: 'select',
              id: 'type',
              label: '类型',
              el: {placeholder: '请选择', clearable: true},
              options:typeOptions
          },
          {
              type: 'select',
              id: 'isuse',
              label: '是否启用',
              el: {placeholder: '请选择', clearable: true},
              options:isuseOptions
          },
          {
              type: 'select',
              id: 'source',
              label: '来源',
              el: {placeholder: '请选择', clearable: true},
              options:sourceOptions
          },
          {
              type: 'select',
              id: 'usetype',
              label: '使用类型',
              el: {placeholder: '请选择', clearable: true},
              options:usetypeOptions
          },
      ]
      return {
        columns,
        typeOptions,
        sourceOptions,
        isuseOptions,
        usetypeOptions,
        goodsList:[],
        skuList:[],
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'excode/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('excode:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            // {
            //   type: 'primary',
            //   text: '删除',
            //   icon: 'el-icon-delete',
            //   show:()=>{return this.hasRole('excode:delete')},
            //   atClick: async row => {
            //     this.handleDelete(row)
            //     return false
            //   },
            // },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('excode:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            // {
            //   text: '批量删除',
            //   type: 'primary',
            //   show:()=>{return this.hasRole('excode:batchDelete')},
            //   disabled: selected => selected.length < 1,
            //   atClick: selected => {
            //     let ids = selected.map(item => item.id)
            //     this.Batch('delete', ids)
            //     return false
            //   },
            // },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            name :[
              {required: true, message: '请输入兑换码名称', trigger: 'blur'},],
            excode :[
              {required: true, message: '请输入兑换码', trigger: 'blur'},],
            operid :[
              {required: true, message: '请输入生成人id', trigger: 'blur'},],
            opername :[
              {required: true, message: '请输入生成人', trigger: 'blur'},],
            goodsid :[
              {required: true, message: '请输入商品id', trigger: 'blur'},],
            skuid :[
              {required: true, message: '请输入商品套餐id', trigger: 'blur'},],
            type :[
                {required: true, message: '请选择类型', trigger: 'blur'},],
            usetype :[
                {required: true, message: '请选择', trigger: 'blur'},],
            source :[
                {required: true, message: '请选择来源', trigger: 'blur'},],
            isuse :[
                {required: true, message: '请选择是否启用', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        typeState(row){
            let data
            this.typeOptions.forEach(r=>{
                if (r.value==row.type){
                    data=r.label
                }
            })
            return data
        },
        sourceState(row){
            let data
            this.sourceOptions.forEach(r=>{
                if (r.value==row.source){
                    data=r.label
                }
            })
            return data
        },
        isuseState(row){
            let data
            this.isuseOptions.forEach(r=>{
                if (r.value==row.isuse){
                    data=r.label
                }
            })
            return data
        },
        usetypeState(row){
            let data
            this.usetypeOptions.forEach(r=>{
                if (r.value==row.usetype){
                    data=r.label
                }
            })
            return data
        },
        getRandomCoding(){
            if (this.type=='update'){
                this.$confirmel('此操作将覆盖用户原有兑换码, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.form.excode=randomCoding()
                }).catch(() => {
                    MessageInfo("已取消");
                });
            }else {
                this.form.excode=randomCoding()
            }
        },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          name : "", // 兑换码名称
          excode : "", // 兑换码
          operid : "", // 生成人id
          opername : "", // 生成人
          goodsid : "", // 商品id
          skuid : "", // 商品套餐id
          type:"",//类型
          source:"",//来源
          isuse:"",//是否启用
          usetype:"",//使用类型
          createTime : "", // 创建时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        row.goodsSku ={}
        row.goodsSpu = {}
        console.log(row)
        this.form = row
        this.getGoodsSkuListInfo(this.form.goodsid)
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteExcode(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelExcode(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertExcode(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateExcode(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
        async getGoodsSpuListInfo() {
            let res = await getGoodsSpuList()
            if (res.status == "200") {
                this.goodsList=res.data.list
            } else {
                MessageError(res.msg)
            }
        },
        async getGoodsSkuListInfo(goodsid) {
            let res = await getGoodsSkuList(goodsid)
            if (res.status == "200") {
                this.skuList=res.data.list
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('excode:update')&&!this.hasRole('excode:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getGoodsSpuListInfo()
      }
    }
  }
</script>

<style scoped>

</style>
